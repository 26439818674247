import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

interface AccordionProps {
  title: string;
  desc: string;
}

const Accordion: FC<AccordionProps> = ({ title, desc }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const button = open ? 'open' : 'close';

  return (
    <div className={styles.accordion}>
      <button className={styles.questionGroup} onClick={toggle} type="button">
        <div className={styles.question}>{t(title)}</div>
        <div className={styles.arrow}>
          <img src={`/iot/${button}.svg`} alt="" />
        </div>
      </button>
      {open && <div className={styles.answer}>{t(desc)}</div>}
    </div>
  );
};

const FinanceFaq: FC = () => {
  const questions = [0, 1, 2, 3, 4];
  const faqPrefix = 'cases.finance.faq';
  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{t(`${faqPrefix}.title`)}</div>
      <div className={styles.titleMobile}>{t(`${faqPrefix}.titleMobile`)}</div>
      <div className={styles.questions}>
        {questions.map((question, index) => (
          <Accordion
            key={question}
            title={`${faqPrefix}.${index}.title`}
            desc={`${faqPrefix}.${index}.desc`}
          />
        ))}
      </div>
    </div>
  );
};

export default FinanceFaq;
