/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { TextInputWithIcon } from '../../../../common/TextInputWithIcon';
import { sendSubscribeToFintech } from '../../../../../utils/api';
import { OopsPanel } from '../../../../common/OopsPanel';
import { getFromUrl } from '../../../../../utils/getFromUrl';
import { LeadMagnetModal } from '../LeadMagnetModal';
import { usePathPrefix } from '../../../../../constants/hooks';

const FinanceDevelopApp: FC = () => {
  const prefixLink = usePathPrefix();
  const prefix = 'cases.finance.developApp';
  const fintechImgSrc = '/images/directions/finance/fintech-img.svg';

  const [inputsState, setInputsState] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showOops, setShowOops] = useState(false);
  const { email, from } = inputsState;
  const path = getFromUrl();

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name } = e.target;
    const { value } = e.target;

    setInputsState((prevState) => ({
      ...prevState,
      [name]: value,
      from: `/${path}`,
    }));
  };

  const validateFields = useCallback(() => {
    // eslint-disable-next-line no-shadow
    const errors = {
      email: !email || email.length <= 2,
    };

    setErrors(errors);

    return !Object.values(errors).some((val) => val);
  }, [email, setErrors]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!validateFields()) return;
      setShowSuccess(true);
      try {
        await sendSubscribeToFintech({ ...inputsState, from });
        setShowSuccess(true);
      } catch (e) {
        setShowOops(true);
      }
    },
    [inputsState]
  );

  const toggleSuccess = useCallback(
    () => setShowSuccess(!showSuccess),
    [showSuccess, setShowSuccess]
  );

  const icons = [
    {
      icon: '/images/directions/finance/icons/app_search.svg',
      list: [...Array(4)],
    },
    {
      icon: '/images/directions/finance/icons/app_develop.svg',
      list: [...Array(6)],
    },
    {
      icon: '/images/directions/finance/icons/app_testing.svg',
      list: [...Array(3)],
    },
  ];

  return (
    <div className={styles.wrap}>
      <h2>{t(`${prefix}.title`)}</h2>
      <p className={styles.desc}>{t(`${prefix}.desc`)}</p>
      <div className={styles.stepsContainer}>
        {icons.map((item, index) => (
          <div key={`step-${index + 1}`} className={styles.step}>
            <div className={styles.head}>
              <img src={item.icon} alt="" />
              <div className={styles.title}>
                {t(`${prefix}.steps.${index}.title`)}
              </div>
            </div>
            <ul className={styles.content}>
              {item.list.map((list, idx) => (
                <li key={`list-${idx + 1}`}>
                  {t(`${prefix}.steps.${index}.list.${idx}`)}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div>
        <div className={styles.magnitBlock}>
          <div className={styles.leftSide}>
            <img src={fintechImgSrc} alt="fin tech img" />
          </div>
          <div className={styles.rightSide}>
            <h2 className={styles.headText}>
              {t(`${prefix}.magnitBlock.title`)}
            </h2>
            <p className={styles.usualText}>
              {t(`${prefix}.magnitBlock.line1`)}
            </p>
            <p className={styles.usualText}>
              {t(`${prefix}.magnitBlock.line2`)}
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit} id="finance-lead-form">
          <div className={styles.formWrapper}>
            <div className={styles.leftSide}>
              <TextInputWithIcon
                name="email"
                value={inputsState.email ?? ''}
                handler={onChange}
                label="cases.blog.email"
                icon="/case/restocare/contact_email.svg"
                hasError={errors.email}
                isSuccess={false}
                id="magnit-fintech-email"
              />
            </div>
            <div className={styles.rightSide}>
              <button type="submit" className={styles.subscribe_button}>
                Отправить
              </button>
            </div>
          </div>
          <div className={styles.terms}>
            {t('cases.restocare.contact.terms')}{' '}
            <a
              href={`${prefixLink}/case/restocare/terms.pdf`}
              target="_blank"
              rel="nofollow noreferrer"
            >
              {t('cases.restocare.contact.personal_data')}
            </a>
          </div>
          <input type="hidden" name="from" id="from" value={path} />
        </form>
        <OopsPanel isOpen={showOops} />
        {showSuccess && (
          <LeadMagnetModal
            onClose={toggleSuccess}
            title="Отправили документ на почту"
            description="Если его нет во «Входящих», проверьте папку «Спам»"
            buttonName="OK"
          />
        )}
      </div>
    </div>
  );
};

export default FinanceDevelopApp;
